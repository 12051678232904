<template>
  <div id="Register">
    <a-form-model
      ref="form-register"
      :model="form.item"
      :rules="form.rules"
      v-bind="{
        labelCol: { span: 0 },
        wrapperCol: { span: 24 },
      }"
    >
      <a-form-model-item has-feedback prop="certificate">
        <a-input
          v-model="form.item.certificate"
          placeholder="请输入您的手机号/邮箱"
        >
          <a-icon slot="prefix" type="user" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item has-feedback prop="real_name">
        <a-input v-model="form.item.real_name" placeholder="请输入您的真实姓名">
          <a-icon slot="prefix" type="idcard" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item has-feedback prop="login_pwd">
        <a-input-password
          v-model="form.item.login_pwd"
          placeholder="请设置您的登录密码（6-18位）"
        >
          <a-icon slot="prefix" type="lock" />
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item has-feedback prop="confirm_login_pwd">
        <a-input-password
          v-model="form.item.confirm_login_pwd"
          placeholder="请输入您设置的登录密码"
        >
          <a-icon slot="prefix" type="lock" />
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item has-feedback prop="pay_pwd">
        <a-input-password
          v-model="form.item.pay_pwd"
          placeholder="请设置您的支付密码（6-18位）"
        >
          <a-icon slot="prefix" type="pay-circle" />
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item has-feedback prop="confirm_pay_pwd">
        <a-input-password
          v-model="form.item.confirm_pay_pwd"
          placeholder="请输入您设置的支付密码"
        >
          <a-icon slot="prefix" type="pay-circle" />
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item has-feedback prop="invitation_code">
        <a-input
          v-model="form.item.invitation_code"
          placeholder="请输入您的邀请码"
        />
      </a-form-model-item>
      <a-form-model-item prop="verify_code">
        <a-input v-model="form.item.verify_code" placeholder="请输入验证码">
          <a-icon slot="prefix" type="code" />
          <a-button
            slot="addonAfter"
            ref="getCodeBtn"
            @click="getCode"
            :disabled="getCodeData.disabled"
          >
            <span v-if="!getCodeData.state">获取验证码</span>
            <span v-else>重新获取({{ getCodeData.waiting }}s)</span>
          </a-button>
        </a-input>
      </a-form-model-item>
      <a-form-model-item :style="{ marginBottom: 0 }" class="form-btn">
        <a-button id="register" @click="form_submit()" type="primary">
          注册帐号
        </a-button>
        <a-button id="goBack" @click="$router.push({ name: 'Login' })">
          返回登陆
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import Vue from "vue";
import { FormModel } from "ant-design-vue";
Vue.use(FormModel);
// import md5 from "js-md5";
let Base64 = require("js-base64").Base64;
import qs from "qs";

export default {
  name: "Register",
  components: {},
  beforeCreate() {},
  data() {
    return {
      getCodeData: {
        state: false,
        waiting: 60,
        intervalID: null,
        disabled: true,
      },
      form: {
        item: {
          certificate: "",
          real_name: "",
          login_pwd: "",
          confirm_login_pwd: "",
          pay_pwd: "",
          confirm_pay_pwd: "",
          invitation_code: (() => {
            return this.$route.query.invitation_code
              ? this.$route.query.invitation_code
              : "";
          })(),
          verify_code: "",
        },
        rules: {
          password: (rule, value, callback) => {
            // 密码校验
            const a = new RegExp(/\s+/g); // 空格
            if (!value) {
              callback(
                `${
                  rule.field === "pay_pwd" ? "支付" : "登录"
                }密码不能为空！（6~18位）`
              );
            } else if (value.length && value.length < 6) {
              callback(
                `${rule.field === "pay_pwd" ? "支付" : "登录"}密码不能小于6位`
              );
            } else if (value.length && 18 < value.length) {
              callback(
                `${rule.field === "pay_pwd" ? "支付" : "登录"}密码不能大于18位`
              );
            } else if (a.test(value)) {
              callback(
                `${
                  rule.field === "pay_pwd" ? "支付" : "登录"
                }密码中不能包涵空格`
              );
            } else {
              callback();
            }
          },
          confirm_password: (rule, value, callback) => {
            // console.log(rule);
            // 再次密码校验
            const a = new RegExp(/\s+/g); //空格
            const b = this.form.item;
            const c = rule.field === "confirm_pay_pwd";
            const login_pwd = c ? b.pay_pwd : b.login_pwd;
            const confirm_login_pwd = c
              ? b.confirm_pay_pwd
              : b.confirm_login_pwd;
            if (!value) {
              callback(`再次输入的${c ? "支付" : "登录"}密码不能为空！`);
            } else if (a.test(value)) {
              callback(`再次输入的${c ? "支付" : "登录"}密码中不能包涵空格`);
            } else if (login_pwd !== confirm_login_pwd) {
              callback(`两次输入不一致！`);
            } else {
              callback();
            }
          },
          certificate: [
            {
              validator: (rule, value, callback) => {
                // 账号校验
                const a = new RegExp(/^1(3|4|5|7|8)\d{9}$/); // 手机号
                const b = new RegExp(/^[1][3,4,5,7,8][0-9]{9}$/); // 手机号
                const c = new RegExp(
                  /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
                ); // 邮箱
                if (this.getCodeData.intervalID !== null)
                  clearInterval(this.getCodeData.intervalID);
                this.getCodeData.disabled = true;
                this.getCodeData.state = false;
                this.getCodeData.waiting = 60;
                this.getCodeData.intervalID = null;
                if (!value) {
                  callback(`手机号/邮箱不能为空`);
                } else if (!((a.test(value) & b.test(value)) | c.test(value))) {
                  callback(`输入正确的手机号/邮箱`);
                } else {
                  callback();
                  this.getCodeData.disabled = false;
                }
              },
            },
          ],
          real_name: [
            {
              validator: (rule, value, callback) => {
                callback(value ? undefined : "真实姓名不能为空");
              },
            },
          ],
          invitation_code: [
            {
              validator: (rule, value, callback) => {
                callback(value ? undefined : "邀请码不能为空");
              },
            },
          ],
          verify_code: [
            {
              validator: (rule, value, callback) => {
                callback(value ? undefined : "验证码不能为空");
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    setFormRules() {
      const a = this.form;
      for (let i in a.item) {
        const b = {
          required: true,
          whitespace: true,
          trigger: ["change", "blur"],
        };
        if (!a.rules) a.rules = {};
        a.rules[i] ? Object.assign(a.rules[i][0], b) : (a.rules[i] = [b]);
      }

      a.rules.login_pwd[0].validator = a.rules.password;
      a.rules.confirm_login_pwd[0].validator = a.rules.confirm_password;
      a.rules.pay_pwd[0].validator = a.rules.password;
      a.rules.confirm_pay_pwd[0].validator = a.rules.confirm_password;

      // console.log(a.rules);
    },
    async getCode(e) {
      e.preventDefault();
      if (this.getCodeData.disabled) {
        this.$message.warning("请先填写手机号/邮箱");
        return true;
      }

      this.getCodeData.state = true;
      this.getCodeData.disabled = true;
      const certificate = this.form.item.certificate;
      if (!certificate) {
        this.$message.warning("请输入您的手机号/邮箱");
      } else {
        const _this = this;
        await this.$axios
          .get("send-verify-code", {
            params: {
              category: "register",
              certificate,
            },
          })
          .then((res) => {
            // console.log("res", res);
            if (res.code === 0) {
              _this.$message.warning("验证码已发送，请注意查看");
              _this.getCodeData.setInterval = setInterval(() => {
                if (_this.getCodeData.waiting) {
                  _this.getCodeData.waiting--;
                } else {
                  clearInterval(_this.getCodeData.setInterval);
                  _this.getCodeData.waiting = 60;
                  _this.getCodeData.state = false;
                  _this.getCodeData.disabled = false;
                }
              }, 1000);
            } else {
              _this.$message.warning(res.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    form_submit() {
      this.$refs["form-register"].validate((valid) => {
        if (valid) {
          const data = this.form.item;
          const datas = {};
          Object.assign(datas, data);

          datas.pay_pwd = Base64.encode(datas.pay_pwd);
          datas.login_pwd = Base64.encode(datas.login_pwd);
          datas.confirm_pay_pwd = Base64.encode(datas.confirm_pay_pwd);
          datas.confirm_login_pwd = Base64.encode(datas.confirm_login_pwd);
          this.$axios
            .post("register", qs.stringify({ ...datas }))
            .then((res) => {
              if (res.code === 0) {
                this.$message.success("注册成功");
                sessionStorage.setItem("user", JSON.stringify(res.data));
                localStorage.setItem("user", JSON.stringify(res.data));
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                console.log(res.code, res.message);
                this.$message.warning(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    this.setFormRules();

    if (this.$route.query.name) {
      this.$message.success(`欢迎您!${this.$route.query.name}的朋友`);
    }
  },
};
</script>

<style lang="less">
#User-Register {
  > .register-form {
    .ant-input-affix-wrapper {
      > .ant-input-prefix,
      > .ant-input-suffix {
        color: white;

        > .ant-input-password-icon {
          color: inherit;
        }
      }
    }

    .AutomaticLanding {
      // text-align: right;
      display: flex;
      justify-content: space-between;

      margin-top: -24px;

      .ant-btn {
        width: auto;
        border: 0;
        display: inline-block;
      }

      * {
        color: #acacac;
      }
    }

    button {
      width: 100%;
      line-height: 2em;
      border-radius: 2em;
      margin-bottom: 0.1rem;

      &#register {
        color: white;
        border: 0;
        background-color: #1668f7;
      }
    }

    .getCode {
      .ant-input-group-addon {
        border: 0;
        padding: 0;
        background-color: inherit;
        // display: inline-block;

        > .ant-btn {
          display: block;
          height: auto;
          margin: 0;
          padding-top: 1px;
          padding-bottom: 1px;
          border-radius: 0;
        }
      }
    }
  }

  > #login-register button {
    position: relative;
    bottom: 0;
    margin-top: 0.3rem;
    width: 100%;
    height: 3em;
    line-height: 3em;
    color: white;
    border-color: #828ac3;
    background-color: #3d447b;
  }
}
</style>
